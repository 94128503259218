
import { Component, Vue } from 'vue-property-decorator'
import modules from '@/store/modules'
import BusifyLogo from '@/components/BusifyLogo.vue'
import { validateRules, validationRules } from '@/utils/rules'
import { handleHomepageRedirect } from '@/utils/router'

@Component({ components: { BusifyLogo } })
export default class Login extends Vue {
  email = ''
  password = ''
  isSubmitting = false
  error = false

  rules = validationRules

  mounted(): void {
    if (modules.auth.getIsTokenSet) {
      this.$router.push({ name: 'home' })
    }
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }
    this.isSubmitting = true
    try {
      await modules.auth.login({ email: this.email, password: this.password })
      await modules.auth.getUserDetail()
      await modules.auth.getUserProfile()
      await modules.app.fetchSystemParameters()
      this.isSubmitting = false
      this.$router.push({ name: handleHomepageRedirect() })
    } catch (error) {
      this.isSubmitting = false
      this.error = true
      return
    }
  }
}
